import { ClipboardCopy } from "assets"
import { useState } from "react"
import { copyToClipboard, createLinkType } from "utils"
import { renderCopiedText } from "./JobDetails"
import { JobDetailSection } from "./JobDetailSection"

export function RecruitmentSection({ t2, recruitment }) {
  return (
    <address>
      <JobDetailSection
        title={t2("Recruitment.title")}
        items={[
          {
            term: t2("Recruitment.telephone"),
            description: createLinkType({
              type: "tel",
              link: recruitment.phone,
              children: recruitment.phone,
            }),
          },
          {
            term: t2("Recruitment.extension"),
            description: createLinkType({
              type: "ext",
              link: recruitment.extension,
              children: recruitment.extension,
            }),
          },
          {
            term: t2("Recruitment.email"),
            description: createLinkType({
              type: "mailto",
              link: recruitment.email,
              children: recruitment.email,
            }),
          },
          {
            term: t2("Recruitment.web"),
            description: createLinkType({
              link: recruitment.url,
              children: recruitment.url,
            }),
          },
        ]}
      />
    </address>
  )
}

export function JobDescriptionSection({ t2, jobDescription }) {
  return (
    <JobDetailSection
      title={t2("JobDescription.title")}
      items={[
        {
          term: t2("JobDescription.full_time"),
          description: jobDescription.fullTime,
        },
        {
          term: t2("JobDescription.number_of_workers"),
          description: jobDescription.numberOfWorkers,
        },
        {
          term: t2("JobDescription.job_duties"),
          description: jobDescription.jobDuties,
        },
        {
          term: t2("JobDescription.job_classification"),
          description: jobDescription.jobClassification,
        },
      ]}
    />
  )
}

export function JobRequirementsSection({ t2, jobRequirements }) {
  return (
    <JobDetailSection
      title={t2("JobRequirements.title")}
      items={[
        {
          term: t2("JobRequirements.experience_required"),
          description: jobRequirements.experienceRequired,
        },
        {
          term: t2("JobRequirements.months_required"),
          description: jobRequirements.monthsRequired,
        },
        {
          term: t2("JobRequirements.special_requirements"),
          description: jobRequirements.specialRequirements,
        },
        {
          term: t2("JobRequirements.number_of_hours"),
          description: jobRequirements.numberOfHours,
        },
        {
          term: t2("JobRequirements.work_schedule"),
          description: jobRequirements.workSchedule,
        },
      ]}
    />
  )
}

export function EmployerInformationSection({ t2, employerInformation }) {
  return (
    <JobDetailSection
      title={t2("EmployerInformation.title")}
      items={[
        {
          term: t2("EmployerInformation.trade_name"),
          description: employerInformation.tradeName,
        },
        {
          term: t2("EmployerInformation.company_name"),
          description: employerInformation.companyName,
        },
        {
          term: t2("EmployerInformation.location"),
          description: employerInformation.location,
        },
        {
          term: t2("EmployerInformation.telephone"),
          description: createLinkType({
            type: "tel",
            link: employerInformation.telephone,
            children: employerInformation.telephone,
          }),
        },
      ]}
    />
  )
}

export function JobOrderDetailsSection({ t2, jobOrderDetails }) {
  return (
    <JobDetailSection
      title={t2("JobOrderDetails.title")}
      items={[
        {
          term: t2("JobOrderDetails.eta_case"),
          description: jobOrderDetails.etaCase,
        },
        {
          term: t2("JobOrderDetails.multiple_worksites"),
          description: jobOrderDetails.multipleWorksites,
        },
        {
          term: t2("JobOrderDetails.additional_wage"),
          description: jobOrderDetails.additionalWage,
        },
        {
          term: t2("JobOrderDetails.date_posted"),
          description: jobOrderDetails.datePosted,
        },
      ]}
    />
  )
}

export function ShareSection({ t2, case_number, copyLink }) {
  const [copied, setCopied] = useState(false)

  const handleCopyLink = () => {
    copyToClipboard(copyLink)
    setCopied(true)
  }

  return (
    <JobDetailSection title={t2("Share.title")}>
      <div className="flex flex-row space-x-2 items-end">
        <input
          title={`Link to case number ${case_number}`}
          readOnly={true}
          aria-disabled="true"
          className="usa-input"
          contentEditable={false}
          defaultValue={copyLink}
          autoComplete="off"
        />
        <button
          type="button"
          onClick={handleCopyLink}
          size="small"
          className="usa-button w-auto flex flex-row space-x-1 items-center py-2.5 bg-gray-200 hover:bg-gray-300 text-sm text-gray-800"
        >
          <span>{renderCopiedText({ copied, t2 })}</span>
          <ClipboardCopy className="h-5 w-5" />
        </button>
      </div>

      <div className="py-10" />
    </JobDetailSection>
  )
}
