import { PDF } from "assets"
import { displayStructuredData } from "utils"

export function renderAwaitingTranslation({ translated, locale, t2 }) {
  if (!translated && locale !== "en") {
    return (
      <div className="flex flex-row mb-4 mt-2">
        <span className="px-2 py-0.5 bg-gray-200 rounded text-xs font-bold text-gray-600">
          {t2("awaiting_translation")}
        </span>
      </div>
    )
  }
  return null
}

export function renderJobOrder({ jobOrderExists, domain, case_number, t2 }) {
  if (jobOrderExists) {
    return (
      <div className="text-dolBlue hover:underline flex flex-row justify-end items-center space-2">
        <a
          target="_blank"
          href={`${domain}/job-order/${case_number}`}
          rel="noreferrer"
        >
          <span className="mr-1">{t2("view_job_order")}</span>
          <PDF />
        </a>
      </div>
    )
  } else {
    return (
      <h2 className="text-gray-500 italic">{t2("job_order_not_available")}</h2>
    )
  }
}

export function renderBackButton({ card, router, t2 }) {
  if (card) {
    return null
  } else {
    return (
      <nav className="bg-gray-100 py-3" aria-label="Back to Job Listings">
        <div className="grid-container">
          <button
            onClick={() => router.push("/jobs")}
            type="button"
            className="usa-button usa-button--outline bg-white text-sm px-2 py-1 "
          >
            {"←"} {t2("back")}
          </button>
        </div>
      </nav>
    )
  }
}

export function renderCopiedText({ copied, t2 }) {
  if (copied) {
    return t2("Share.copied")
  } else {
    return t2("Share.copy")
  }
}

export function renderStructuredData({ card, case_number, structuredData }) {
  if (card) {
    return null
  } else {
    return displayStructuredData(case_number, structuredData)
  }
}
