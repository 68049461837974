import useSWR from "swr"
import { useEffect, useRef, useState } from "react"
import { useController } from "react-hook-form"
import { Label } from "@trussworks/react-uswds"
import { markMatches } from "utils"
import { useOnClickOutside, useDebounce } from "hooks"
import { useRouter } from "next/router"
import { useTranslations } from "use-intl"
import { CurrentLocation } from "./JobSearch"

export function Autosuggest({
  name,
  translationName,
  control,
  defaultValue,
  setValue,
  label,
  helper,
  fetcher,
  which,
}) {
  const SEARCH_TEXT = "@search.text"

  const { field } = useController({
    name,
    control,
    // rules: { required: true },
    defaultValue,
  })

  const router = useRouter()

  const t = useTranslations("FindJobs")

  const selection = field.value

  const debouncedSelection = useDebounce(selection, 500)

  const [options, setOptions] = useState([])

  const [listboxOpen, setListboxOpen] = useState(false)

  const listboxRef = useRef()

  const [focusedIndex, setFocusedIndex] = useState(null)

  useOnClickOutside(listboxRef, () => setListboxOpen(false))

  const { data, error } = useSWR(
    selection
      ? [`suggestions-${name}-${which}`, debouncedSelection, router.locale]
      : null,
    fetcher
  )

  const focusListbox = (n) => {
    if (listboxOpen) {
      const id = `autocomplete--${name}-${n}`
      const item = document.getElementById(id)
      if (item) {
        item.focus()
      }
    }
  }

  function focusInput() {
    const id = `input-${name}`
    const item = document.getElementById(id)
    if (item) {
      item.focus()
    }
  }

  const handleOnClickOrSelect = (e, id) => {
    let value
    if (id) {
      value = document.getElementById(id).innerText
    } else if (e && e.type === "click") {
      value = e.target.innerText
    } else {
      value =
        options?.[name === "location" ? focusedIndex - 1 : focusedIndex]?.[
          SEARCH_TEXT
        ]
    }
    setValue(name, value)
    setListboxOpen(false)
    setFocusedIndex(0)
  }

  useEffect(() => {
    if (data && data.length) {
      let clean = []

      if (name === "search") {
        data.forEach((item) => {
          let exist = false
          clean.map((node) => {
            if (
              node[SEARCH_TEXT].toLowerCase() ===
              item[SEARCH_TEXT].toLowerCase()
            ) {
              exist = true
            }
          })
          if (!exist) clean.push(item)
        })
      } else {
        // name === "location"
        data.forEach((item) => {
          clean.push({
            [SEARCH_TEXT]: item.address.freeformAddress,
          })
        })
      }

      setOptions(clean)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  function userTyping(e) {
    if (selection?.trim().length > 0) {
      setListboxOpen(true)
    }
  }

  function handleOnKeyUp(e) {
    switch (e.key) {
      case "Escape":
      case "ArrowUp":
      case "ArrowLeft":
      case "ArrowRight":
      case " ":
      case "Enter":
      case "Shift":
      case "Meta":
      case "Tab":
        // ignore otherwise the menu will show
        break
      case "ArrowDown":
        e.stopPropagation()
        e.preventDefault()
        focusListbox(0)
        setFocusedIndex(0)
        break
      // default:
      //   userTyping(e)
    }
  }

  // Hide the menu by listening to the tab key
  function handleOnKeyDownInput(e) {
    switch (e.key) {
      case "Escape":
      case "ArrowUp":
      case "ArrowLeft":
      case "ArrowRight":
      case " ":
      case "Enter":
      case "Shift":
      case "Meta":
        break
      case "Tab":
        // Hide the menu
        setListboxOpen(false)
        setFocusedIndex(0)
        break
      default:
        userTyping(e)
    }
  }

  function handleOnKeyDownMenu(e, id) {
    switch (e.key) {
      case "Tab":
        // Hide the menu
        setListboxOpen(false)
        setFocusedIndex(0)
        break
      case "ArrowUp":
        e.stopPropagation()
        e.preventDefault()
        // If the first option is focused, set focus to the text box.
        if (focusedIndex === 0) {
          focusInput()
        } else {
          // Otherwise set focus to the previous option.
          setFocusedIndex((prev) => {
            focusListbox(prev - 1)
            return prev - 1
          })
        }
        break
      case "ArrowDown":
        e.stopPropagation()
        e.preventDefault()
        // Focus the next menu option. If it’s the last menu option, do nothing.
        if (focusedIndex < options.length) {
          setFocusedIndex((prev) => {
            focusListbox(prev + 1)
            return prev + 1
          })
        }
        break
      case "Enter":
      case " ":
        e.stopPropagation()
        e.preventDefault()
        // Select the currently highlighted option and focus the text box.
        handleOnClickOrSelect()
        focusInput()
        break
      case "Escape":
        // Hide the menu and focus the text box.
        setListboxOpen(false)
        setFocusedIndex(0)
        focusInput()
        break
    }
  }

  return (
    <div className="relative">
      <div>
        <Label htmlFor={`input-${name}`} className="font-bold mt-0">
          {label}
        </Label>
        <small className="text-gray-600">{helper}</small>
      </div>
      <input
        id={`input-${name}`}
        className="min-w-full usa-input"
        autoCapitalize="none"
        type="text"
        autoComplete="off"
        aria-autocomplete="list"
        aria-expanded={listboxOpen}
        aria-controls={`autocomplete-options--${name}`}
        role="combobox"
        onKeyUp={handleOnKeyUp}
        onKeyDown={handleOnKeyDownInput}
        maxLength={255}
        {...field}
      />

      {error ? (
        <div className="absolute">
          <small className="rounded-md bg-gray-200 px-2 text-xs font-bold text-gray-700">
            {t(`${translationName}.error_fetch`)}
          </small>
        </div>
      ) : null}

      <ul
        ref={listboxRef}
        id={`autocomplete-options--${name}`}
        role="listbox"
        onKeyDown={handleOnKeyDownMenu}
        className={
          listboxOpen && options.length > 0
            ? `block absolute border border-gray-500 mt-2 w-full z-10 bg-white`
            : `hidden`
        }
      >
        {name === "location" ? (
          <CurrentLocation
            setValue={setValue}
            focusInput={focusInput}
            setListboxOpen={setListboxOpen}
          />
        ) : null}
        {options?.map((option, index) => {
          const n = name === "location" ? index + 1 : index
          const id = `autocomplete--${name}-${n}`
          return (
            <li
              onClick={(e) => handleOnClickOrSelect(e, id)}
              id={id}
              key={`${name}-${option}-${n}`}
              role="option"
              tabIndex="-1"
              aria-selected={option[SEARCH_TEXT] === selection}
              data-option-value={option[SEARCH_TEXT]}
              className="px-2 py-1.5 hover:bg-gray-200 focus:bg-gray-200 cursor-pointer"
            >
              {markMatches({
                string: option[SEARCH_TEXT],
                mark: selection,
                parentId: `autocomplete--${name}-${n}`,
              })}
            </li>
          )
        })}

        <div aria-live="polite" role="status" className="sr-only">
          {options.length} ${t(`${translationName}.suggestions_available`)}.
        </div>
      </ul>
    </div>
  )
}
