import DOMPurify from "isomorphic-dompurify"

// sanitize objects
export function sanitize(dirty) {
  if (dirty && dirty.constructor === Object) {
    let clone = JSON.parse(JSON.stringify(dirty))
    let cloneKeys = Object.keys(clone)
    for (let i of cloneKeys) {
      const cloneKey = cloneKeys[i]
      clone[cloneKey] = DOMPurify.sanitize(clone[cloneKey])
    }
    return clone
  }
  return null
}
