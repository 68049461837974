import { isNa } from "utils"

export function JobDetailSection({
  title,
  items,
  children,
  tw = "",
  ...props
}) {
  return (
    <section className={`border-t-2 border-gray-200 py-4 ${tw}`} {...props}>
      <h2 className={"text-primary-dark font-bold font-serif"}>{title}</h2>

      <dl className="mt-3 flex flex-col space-y-3">
        {items?.map((item, index) => {
          if (isNa(item.description)) return null
          const isWebAddress = item.term.indexOf("Web address") > -1
          return (
            <div
              key={`${title}-${index}`}
              className="flex flex-col flex-shrink-0 xl:flex-row xl:space-x-4 "
            >
              <dt className={"flex-none font-bold w-64"}>{item.term}</dt>
              <dd
                className={`flex flex-1 ${
                  isWebAddress ? "break-all" : ""
                } max-w-prose `}
              >
                {item.description}
              </dd>
            </div>
          )
        })}
      </dl>

      {children}
    </section>
  )
}
