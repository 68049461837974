import { useState } from "react"
import { useTranslations } from "use-intl"
import { Alert } from "@trussworks/react-uswds"
import { richResolver } from "utils"
import { callNextAPI } from "utils/fetchers/."
import { Form, Select, Input } from "../Form"
import * as yup from "yup"
import moment from "moment"
import "moment/locale/es"

export function SaveSubscriptionCard({
  search,
  location,
  radius,
  wageType,
  date,
  jobType,
  jobStatus,
  sort,
  locale,
  azureSearch,
  close,
  setAlert,
}) {
  const sm = useTranslations("JobSearch.SaveModal")
  const sf = useTranslations("JobSearch.SaveFilters")
  const sa = useTranslations("Shared.Alerts")

  /**
   * ! Label variables are ones displayed to the user.
   * ! The other corresponding one (ie., date vs. dateLabel) is saved to Datahub for Azure Search on their end.
   */

  let dateLabel
  if (typeof date === "object") {
    dateLabel = moment(date).locale(locale).format("LL")
  }

  /**
   * This is a special  data transformation for Advanced Search.
   * We always create a subscription with active jobs only.
   */
  if (
    jobStatus &&
    jobStatus === "all" &&
    azureSearch?.azFilter[0] === "(display ne false)"
  ) {
    azureSearch.azFilter[0] = "active eq true and display eq true"
  }

  const normalizeDateToISO = function (date) {
    if (date instanceof Date) {
      return date.toISOString()
    } else if (!!date) {
      return new Date(date).toISOString()
    }

    return date
  }

  const wageTypeLabel = wageType?.toLowerCase().trim()
  let jobTypeLabel = jobType?.toLowerCase().trim()

  const [loading, setLoading] = useState(false)

  const [status, setStatus] = useState({})

  let schema = yup
    .object()
    .shape({
      email: yup.string().max(100).email().required(),
      confirmEmail: yup
        .string()
        .max(100)
        .email()
        .test("match", "E-mail does not match.", function () {
          return this.parent.email === this.parent.confirmEmail
        })
        .required(),
      subscriptionLabel: yup.string().max(100).required(),
      frequency: yup.string().max(100).required(),
      language: yup.string().max(100).required(),
    })
    .required()

  async function onSubmit(values, code) {
    if (!code) {
      return null
    }

    // datahub requires date be in ISO format
    let _date = normalizeDateToISO(date)
    const datahubObject = {
      email: values?.email,
      frequency: values?.frequency.toUpperCase(),
      language: values?.language.toUpperCase(),
      displayName: values?.subscriptionLabel,
      jobSearchText: search?.length > 0 ? search : null,
      jobSearchLocation: location?.length > 0 ? location : null,
      jobFilterLocationMiles: radius?.length > 0 ? radius : null,
      jobFilterWageType: wageType?.length > 0 ? wageType : "all", // all instead of null to account for advanced search duplicates
      jobFilterBeginOnAfterDate: _date?.length > 0 ? _date : null,
      jobFilterJobType: jobType?.length > 0 ? jobType : null,
      jobSortBy: sort?.length > 0 ? sort : "",
      azFilter: azureSearch?.azFilter.join(" and "),
      azOrderBy: azureSearch?.azOrderBy.join(","),
      azSearch: azureSearch?.azSearch,
    }

    try {
      setLoading(true)
      const response = await callNextAPI({
        url: "/api/subscribe",
        payload: { params: datahubObject, code },
      })

      if (response) {
        setLoading(false)
        setStatus(response.status)
        setAlert(true)
      }
    } catch (e) {
      console.error(e)
      setLoading(false)
      setStatus({ general: true })
      setAlert(true)
      // close()
    }
  }

  function renderAlert() {
    if (status?.submitted) {
      return (
        <Alert
          className="order-first lg:order-last md:px-4 lg:px-8 lg:space-x-4 grid-container"
          type="success"
          headingLevel="h2"
          heading={<h2>{sa("sub_success")}</h2>}
        ></Alert>
      )
    } else if (
      status?.recaptcha ||
      status?.general ||
      status?.duplicate ||
      status?.maxPerEmail
    ) {
      const hasRecaptchaError = status?.recaptcha
      const hasMaxPerEmailError = status?.maxPerEmail
      const hasGeneralError = status?.general

      let errorType

      // Determine the type of error based on the properties present in the status object
      if (hasRecaptchaError) {
        errorType = "sub_recaptcha_err"
      } else if (hasMaxPerEmailError) {
        errorType = "sub_maxperemail_err"
      } else if (hasGeneralError) {
        errorType = "sub_general_err"
      } else {
        errorType = "sub_duplicate_err"
      }

      return (
        <Alert
          type="error"
          className="order-first lg:order-last md:px-4 lg:px-8 lg:space-x-4 grid-container"
          slim
        >
          {sa.rich(errorType, richResolver)}
        </Alert>
      )
    }
    return null
  }

  if (
    status?.submitted ||
    status?.recaptcha ||
    status?.general ||
    status?.duplicate ||
    status?.maxPerEmail
  ) {
    return renderAlert()
  }

  return (
    <div className="p-10">
      <h2 className="text-primary-dark text-2xl font-bold font-serif border-b-4 rounded-6 border-primary-dark pb-2 mb-5">
        {sm("title")}
      </h2>

      <div className="text-lg mb-3">
        <p>
          {sm("search")}:&nbsp;{search ? search : sm("none")}
        </p>
        <p>
          {sm("location")}:&nbsp;{location ? location : sm("none")}
        </p>
        <span>{sm("filters")}: </span>
        {radius ? (
          <span>
            {radius} {sm("radius")} |{" "}
          </span>
        ) : null}
        {dateLabel ? (
          <span>
            {sm("begin")} {dateLabel} |{" "}
          </span>
        ) : null}
        {wageTypeLabel ? (
          <span>
            {sf(wageTypeLabel)} {sm("wage_type")} |{" "}
          </span>
        ) : null}
        {jobTypeLabel ? (
          <span>
            {sf(jobTypeLabel)} {sm("job_type")} |{" "}
          </span>
        ) : null}
        {sort ? (
          <span>
            {sm("sort")} {sf(sort)}
          </span>
        ) : null}
      </div>

      <span className="italic">{sm("active_jobs_only")}</span>

      <Form
        onSubmit={onSubmit}
        schema={schema}
        loading={loading}
        className="p-6 lg:py-6 lg:px-8 bg-gray-100  rounded-lg"
      >
        <legend className="text-2xl font-bold mt-2 sr-only">{"tsting"}</legend>
        <Input name="email" label={sm("email")} />
        <Input name="confirmEmail" label={sm("confirm_email")} />
        <Input name="subscriptionLabel" label={sm("name")} />
        <Select
          label={sm("frequency")}
          name="frequency"
          defaultValue="Daily"
          //   disabled={true}
          values={["Daily", "Weekly"]}
          options={[sm("daily"), sm("weekly")]}
          //   optionMapper={"topic_"}
          //   optionT={t}
        />
        <Select
          label={sm("language")}
          name="language"
          defaultValue={locale === "en" ? "English" : "Spanish"}
          //   disabled={true}
          values={["English", "Spanish"]}
          options={[sm("english"), sm("spanish")]}
          //   optionMapper={"topic_"}
          //   optionT={t}
        />
      </Form>
    </div>
  )
}
